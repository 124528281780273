.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  border-radius: 10px;
}
footer {
  flex: 1;
}
footer nav {
  height: 100%;
}
.App-header {
  font-size: calc(10px + 2vmin);
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.navbar-themed {
  background-color: rgba(231, 231, 231, 0.342);
  box-shadow: 0px 0px 3px 0px rgba(189, 189, 189, 0.81);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.th {
  font-weight: 600;
}
/* tbody tr:nth-child(odd) {
  background-color: hsl(202deg 67% 95%);
} */
#tooltip-grid > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
  white-space: pre-line;
  max-width: 450px;
}

#tooltip-grid > .tooltip-arrow {
  border-top: 5px solid #062e56;
}

.bootstrap h6,
.bootstrap-dark h6,
.MuiButton-label {
  font-weight: 600;
}

.bootstrap-dark .MuiTableCell-head {
  background-color: #4a4a4a;
}
.bootstrap .MuiTableCell-head {
  background-color: #f7f7f7;
}
.case-Journal .MuiTableRow-hover {
  cursor: pointer;
}
.bootstrap .burger div {
  background: rgba(0, 0, 0, 0.5);
}
.bootstrap-dark .burger div {
  background: rgba(255, 255, 255, 0.5);
}
.MuiDrawer-paperAnchorLeft {
  background: linear-gradient(0deg, #3358f4, #1d8cf8);
  color: #fff !important;
}
.MuiDrawer-paperAnchorLeft .MuiSvgIcon-root {
  color: #fff;
}
.MuiDrawer-paperAnchorLeft a {
  color: #fff;
  text-decoration: none;
}
.MuiDrawer-paperAnchorLeft a:hover {
  color: #fff;
  text-decoration: none;
}
main {
  min-height: calc(100vh - 120px);
}
@media (min-width: 1400px) {
  .bootstrap .container {
    max-width: 1360px;
  }
  .modal-dialog.modal-xxl {
    max-width: 80vw;
  }
}
@media (max-width: 680px) {
  .bootstrap footer .navbar-expand .navbar-nav {
    flex-direction: column;
  }
}
@media (min-width: 680px) {
  footer {
    display: block !important;
  }
}

.v-animal-image {
  height:auto !important;
}

.MuiDataGrid-root--densityStandard {
  border:0;
}